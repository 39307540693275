import { Injectable } from '@angular/core';
import { action, observable } from 'mobx-angular';
import { Shop } from '../models/shop.model';

@Injectable()
class ShopStore {
  @observable shops: Shop[];
  @observable shop: Shop;
  @observable inactiveShops: Shop[];
  @observable activeShops: Shop[];

  constructor() {
    this.initialize();
  }

  @action
  private initialize() {
    this.shops = [];
  }

  @action
  setShop(shop) {
    this.shop = shop;
  }

  @action
  updateShops(shops) {
    this.shops = shops;
  }

  @action
  setActiveShops(shops) {
    this.activeShops = shops;
  }

  @action
  setInactiveShops(shops) {
    this.inactiveShops = shops;
  }

  @action
  removeShop(id) {
    this.shops = this.shops.filter((shop) => shop.id !== id);
  }
}

export default ShopStore;
