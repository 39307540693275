import { Injectable } from '@angular/core';

@Injectable()
export class ShopSlotsParserService {
  parse(slotData) {
    const { designData, maxLength, textColors, imageFilters, ...slot } = slotData;
    if (!designData) {
      return;
    }

    const { src } = designData.objects[0];

    const customizable = designData.editType === 'customizable';
    const swappable = designData.editType === 'swap';
    const tapToPropagate = designData.editType === 'tapToPropagate';
    const customizableAndSwappable = designData.editType === 'customizableAndSwappable';

    if (slot.type === 'text') {
      return {
        ...slot,
        designData,
        customizable: customizable || customizableAndSwappable,
        text: designData.objects[0].text,
        fill: designData.objects[0].fill,
        maxLength: maxLength || -1,
        dynamicColor: textColors ? textColors.dynamicColor : false,
        textColors: textColors ? textColors.allowedColors : [],
      };
    }

    return {
      ...slot,
      designData,
      src,
      tapToPropagate,
      customizable: customizable || customizableAndSwappable,
      swappable: swappable || customizableAndSwappable,
      imageFilters: { grayscale: imageFilters ? imageFilters.grayscale : undefined },
    };
  }
}
