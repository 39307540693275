import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ColorService } from '../../core/services/color.service';

@Component({
  selector: 'app-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss'],
})
export class ColorInputComponent implements OnInit {
  @Input() color: string = '#';
  // tslint:disable-next-line:no-output-on-prefix
  @Output() colorChange = new EventEmitter();

  constructor(private colorService: ColorService) {}

  ngOnInit() {
    const rgb = this.colorService.hexToRgb(this.color);

    if (!rgb) {
      this.color = '#';
    }
  }

  get textColor() {
    return this.colorService.getTextColor(this.color);
  }

  format(value) {
    if (value === '') {
      return '#';
    }

    return value
      .match(/[0-9A-F#]/gi)
      .filter((_, index) => index < 9)
      .join('')
      .toUpperCase();
  }

  handleChange($event) {
    this.color = this.format($event.target.value);

    if (this.color.length !== 9 && this.color.length !== 7) {
      return;
    }

    const rgb = this.colorService.hexToRgb(this.color);

    if (!rgb) {
      return;
    }

    this.colorChange.emit(this.color);
  }
}
