import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { UserStore } from '../core/stores';

@Injectable()
export class HomeAuthResolver implements Resolve<boolean> {
  constructor(private userStore: UserStore) {}

  resolve() {
    return this.userStore.isAuthenticated;
  }
}
