import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-login-page',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent {
  error: string;
  loginForm: FormGroup;
  deviceInfo: any;

  constructor(
    private deviceService: DeviceDetectorService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.detectBrowser();
    this.error = this.activatedRoute.snapshot.queryParamMap.get('error');
  }

  detectBrowser() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  needsUpdate() {
    if (
      this.deviceInfo.browser.toLocaleLowerCase() === 'chrome' ||
      this.deviceInfo.browser.toLocaleLowerCase() === 'safari'
    ) {
      if (
        this.deviceInfo.browser.toLocaleLowerCase() === 'chrome' &&
        parseInt(this.deviceInfo.browser_version, 10) >= 68
      ) {
        return false;
      }
      if (
        this.deviceInfo.browser.toLocaleLowerCase() === 'safari' &&
        parseInt(this.deviceInfo.browser_version, 10) >= 11
      ) {
        return false;
      }
    }

    return true;
  }

  async login() {
    const { username, password } = this.loginForm.value;
    try {
      const user = await Auth.signIn(username, password);
      if (user) {
        return this.router.navigateByUrl('/');
      }
    } catch (error) {
      this.error = JSON.stringify(error);
    }
  }
}
