import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import * as WebFont from 'webfontloader';

import { Font } from '../models/font.model';
import { ApiService } from './api.service';

@Injectable()
export class FontsService {
  constructor(private apiService: ApiService) {}

  availableFonts(): Promise<Font[]> {
    return this.apiService
      .get('/font')
      .pipe(map((data: Font[]) => data))
      .toPromise();
  }

  async update(font: Font) {
    return this.apiService.put('/font', { font }).toPromise();
  }

  async delete(font: Font) {
    return this.apiService.delete(`/font/${font.id}`).toPromise();
  }

  async save(font: Font) {
    if (font.id) {
      return this.apiService.put('/font', { font }).toPromise();
    }

    return this.apiService.post('/font', { font }).toPromise();
  }

  async loadFonts(fonts: Font[]) {
    const families = fonts.map((font) => font.fontFamily);
    const urls = fonts.map((font) => font.url);

    WebFont.load({
      custom: {
        families,
        urls,
      },
      fontinactive: (familyName, fvd) => {
        // tslint:disable-next-line:no-console
        console.error(`error loading font ${familyName} ${fvd}`);
      },
    });
  }
}
