import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShopTemplateModalAddColorStepComponent } from '../../shop/shop-templates/shop-template-walkthrough/shop-template-walkthrough-modal-add-step/shop-template-modal-add-step-color';

@Component({
  selector: 'app-color-step',
  templateUrl: './app-color-step.component.html',
  styleUrls: ['./app-color-step.component.scss'],
})
export class AppColorStepComponent {
  @Input() allowedColors: any[];
  @Output() newColorToAdd = new EventEmitter();
  @Output() dropColorInAllowedColors = new EventEmitter();

  constructor(private modalService: NgbModal) {}

  addColorStepTutorial() {
    const modalRef = this.modalService.open(ShopTemplateModalAddColorStepComponent, { size: 'sm' });
    modalRef.result.then((newColor) => {
      if (!!this.allowedColors) {
        this.allowedColors = [...this.allowedColors, newColor];
      } else {
        this.allowedColors = [newColor];
      }
      this.newColorToAdd.emit(newColor);
    });
  }

  deleteColorStepTutorial(event) {
    const myIndex = this.allowedColors.indexOf(event.target.getAttribute('data-color'));
    if (myIndex !== -1) {
      this.allowedColors.splice(myIndex, 1);
      this.dropColorInAllowedColors.emit(this.allowedColors);
    }
  }
}
