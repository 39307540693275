import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Storefront } from '../../../../shared/models/storefront.model';
import { ApiService } from './api.service';

@Injectable()
export class ShopStorefrontService {
  constructor(private apiService: ApiService) {}

  get(): Promise<Storefront> {
    return this.apiService.get('/storefront').toPromise();
  }

  save(storefront: Storefront): Promise<Storefront> {
    if (!storefront.id) {
      return this.apiService
        .post(`/storefront`, storefront)
        .pipe(map((data: Storefront) => data as Storefront))
        .toPromise();
    }

    return this.apiService
      .put(`/storefront`, storefront)
      .pipe(map((data: Storefront) => data as Storefront))
      .toPromise();
  }
}
