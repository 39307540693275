import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeAuthResolver } from './home-auth-resolver.service';
import { HomeComponent } from './home.component';

const routes: Routes = [
  {
    component: HomeComponent,
    path: '',
    resolve: {
      isAuthenticated: HomeAuthResolver,
    },
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class HomeRoutingModule {}
