import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ShopDomain } from '../models/shop-domain.model';
import { ApiService } from './api.service';

@Injectable()
export class ShopDomainsService {
  constructor(private apiService: ApiService) {}

  getAll(): Promise<ShopDomain[]> {
    return this.apiService
      .get(`/domains`)
      .pipe(map((data: ShopDomain[]) => data as ShopDomain[]))
      .toPromise();
  }

  save(domains: string[]): Promise<ShopDomain[]> {
    return this.apiService
      .put(`/domains`, { domains })
      .pipe(map((data: ShopDomain[]) => data as ShopDomain[]))
      .toPromise();
  }
}
