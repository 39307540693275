import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DesignsService } from '../../../core/services/designs.service';
import { BaseFilterComponent } from '../base-filter-component.abstract';

interface FilterableItem {
  id: string;
  name: string;
  value?: any;
}

interface FilterableItemGroup {
  name: string;
  items: FilterableItem[];
}

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
  providers: [{ provide: BaseFilterComponent, useExisting: forwardRef(() => FilterDropdownComponent) }],
})
export class FilterDropdownComponent extends BaseFilterComponent implements OnChanges {
  @Input() key: string = 'select';
  @Input() label: string = 'Select';
  @Input() disabled: boolean = false;
  /*
   * type
   *  flags => dropdown handled as multiple flags grouped together, meaning it'll add a new attribute to the filter object for each selected item.
   *    For example, a status dropdown containing 2 items (option1 and option2) will update the filter with single values filter.option1 and filter.options2.
   *  multi => dropdown handled as an array of selected items.
   *    For example, a dropdown filter of Shops will update the filter with an array of shops - filter.shops = [id1, id2].
   *  single (default) => dropdown handled normally as a single selectable value.
   *    For example, a dropdown filter of Shops will update the filter with one shop - filter.shops = id1.
   */
  @Input() type: 'flags' | 'multi' | 'single' = 'single';
  @Input() collection: FilterableItem[];
  @Input() groupedCollection: FilterableItemGroup[];
  @Input() dropdownShop: boolean;
  @Input() allPendingDesigns: number;
  @Input() allReviewingDesigns: number;
  @Input() grouped: boolean = false;
  @Input() change: (filter: any) => void;
  @Input() counterDesignsShopsNew: object;
  @Input() counterDesignsShopsInreview: object;
  @Output() countAllShops = new EventEmitter<object>();
  flagChanged: boolean = false;
  shopsArray: any[] = [];
  constructor(private designService: DesignsService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    /* tslint:disable-next-line */
    if (this.dropdownShop && this.collection.length > 1 && !this.flagChanged) {
      this.collection.map((shop) => {
        this.shopsArray.push(shop.id);
      });
      this.designService.getPendingDesignsWithShop(this.shopsArray).then((data) => {
        if (data[0] && data[1]) {
          this.countAllShops.emit({
            counterDesignsShopsNew: !data[0].in_review ? data[0] : data[1],
            counterDesignsShopsInreview: data[0].in_review ? data[0] : data[1],
          });
        } else if (data[0] && !data[1]) {
          const dataIndex1 = {};
          this.shopsArray.map((shopId) => {
            dataIndex1[shopId] = '0';
          });
          this.countAllShops.emit({
            counterDesignsShopsNew: !data[0].in_review ? data[0] : dataIndex1,
            counterDesignsShopsInreview: data[0].in_review ? data[0] : dataIndex1,
          });
        }
      });
      this.flagChanged = true;
    }
  }

  handleSelection(itemId) {
    if (!this.change) {
      return;
    }

    let selectedItem;
    if (!this.grouped) {
      selectedItem = this.collection.find((item) => item.id === itemId);
    } else {
      for (let i = 0; i < this.groupedCollection.length; i++) {
        selectedItem = this.groupedCollection[i].items.find((curr) => curr.id === itemId);

        if (selectedItem) {
          i = this.groupedCollection.length;
        }
      }
    }

    const { id, name, value } = selectedItem;

    const isFlag = this.type === 'flags';

    const filterItem = {
      key: isFlag ? id : this.key,
      label: this.label,
      type: this.type,
      name,
      value: value || id,
    };

    this.change(filterItem);
  }
}
