import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Design } from '../../../core/models/design.model';
import { Errors } from '../../../core/models/errors.model';
import { ShopTemplate } from '../../../core/models/shop-template.model';
import { ShopTemplatesService } from '../../../core/services/shop-templates.service';
import { ShopStore } from '../../../core/stores';

@Component({
  selector: 'app-design-template-form',
  templateUrl: './design-template-form.component.html',
  styleUrls: ['./design-template-form.component.scss'],
  providers: [ShopTemplatesService],
})
export class DesignTemplateFormComponent implements OnInit {
  @Input() design: Design;

  errors: Errors = { errors: {} };
  isSubmitting = false;
  templateForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private templatesService: ShopTemplatesService,
    public shopStore: ShopStore,
    private activeModal: NgbActiveModal,
  ) {}

  createForm() {
    let formGroup;
    formGroup = {
      name: [null, Validators.required],
      shop: [null, Validators.required],
    };
    this.templateForm = this.formBuilder.group(formGroup);
  }

  getDesignImage() {
    if (this.design.proofBack && !this.design.proofFront) {
      return this.design.proofBack;
    }

    if (this.design.proofFront) {
      return this.design.proofFront;
    }
  }

  ngOnInit() {
    this.createForm();
  }

  submitForm() {
    if (this.isSubmitting) {
      return false;
    }
    this.isSubmitting = true;
    this.errors = { errors: {} };
    const payload = this.templateForm.value;
    payload.design = this.design.id;
    this.templatesService.create(payload).subscribe(
      (template: ShopTemplate) => {
        this.isSubmitting = false;
        this.activeModal.close(template);
      },
      (err) => {
        this.errors = err;
        this.isSubmitting = false;
      },
    );
  }

  public close() {
    this.activeModal.close();
  }
}
