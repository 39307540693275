import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-shop-template-modal-add-step-color',
  templateUrl: './shop-template-modal-add-step-color.component.html',
  styleUrls: ['./shop-template-modal-add-step-color.component.scss'],
})
export class ShopTemplateModalAddColorStepComponent {
  currentColor: string;

  constructor(private activeModal: NgbActiveModal) {}

  colorChange(typedColor) {
    this.currentColor = typedColor;
  }

  save() {
    this.activeModal.close(this.currentColor);
  }

  close() {
    // Close the displayed modal
    this.activeModal.close();
  }
}
