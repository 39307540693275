import { Component, forwardRef, Input } from '@angular/core';
import { BaseFilterComponent } from '../base-filter-component.abstract';

export interface ToggleOption {
  label: string;
  checked: boolean;
  value: any;
}

@Component({
  selector: 'app-filter-toggle',
  templateUrl: './filter-toggle.component.html',
  styleUrls: ['./filter-toggle.component.scss'],
  providers: [{ provide: BaseFilterComponent, useExisting: forwardRef(() => FilterToggleComponent) }],
})
export class FilterToggleComponent extends BaseFilterComponent {
  @Input() key: string = 'select';
  @Input() label: string = 'Select';
  @Input() toggleOptions: ToggleOption[];
  @Input() toggleType: 'filter' | 'sort' = 'sort';
  @Input() change: (filter: any) => void;

  @Input() selectedToggle: ToggleOption;

  handleToggleSelection() {
    if (!this.change) {
      return;
    }

    this.toggleOptions.map((item) => {
      if (item.checked) {
        item.checked = false;

        return;
      }

      item.checked = true;
      this.selectedToggle = item;
    });

    const filterItem = {
      key: this.key,
      label: this.selectedToggle.label,
      value: this.selectedToggle.value,
    };

    this.change(filterItem);
  }
}
