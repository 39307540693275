import { Injectable } from '@angular/core';

@Injectable()
export class AccessControlService {
  grantList: any[];

  setGrants(grantList) {
    this.grantList = grantList;
  }

  hasPermissionTo(action: string, resource: string) {
    let hasPermission = false;
    if (this.grantList && this.grantList.length) {
      this.grantList.forEach((permission) => {
        if (permission.role === 'SUPERADMIN' || (permission.resource === resource && permission.action === action)) {
          hasPermission = true;
        }
      });
    }

    return hasPermission;
  }
}
