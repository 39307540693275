import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';

import { BaseFilterComponent } from './base-filter-component.abstract';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements AfterContentInit {
  filters = {};
  chips = [];

  @Input() initValues: any[];
  @Input() noMargin: boolean = false;
  @Input() searchOnTheFly: boolean = true;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() update = new EventEmitter();

  @ContentChildren(BaseFilterComponent) filterComponents: QueryList<BaseFilterComponent>;

  ngAfterContentInit() {
    this.filterComponents.forEach((filterComponent) => (filterComponent.change = this.handleChildChange.bind(this)));

    if (this.initValues) {
      this.initValues.forEach((filter) => this.handleChildChange(filter, true));
    }
  }

  handleChildChange(filter, shouldNotFireChange?: boolean) {
    if (filter.type && filter.type !== 'text') {
      this.addChip(filter);
    }
    if (filter.type === 'text' && !this.searchOnTheFly && filter.keyCode !== 13) {
      shouldNotFireChange = true;
    }
    this.addFilter(filter, shouldNotFireChange);
  }

  addChip({ type, ...chip }) {
    if (type === 'single' && this.filters[chip.key] !== undefined) {
      if (!chip.value) {
        this.chips = this.chips.filter((item) => item.key !== chip.key);

        return;
      }

      const chipIndex = this.chips.findIndex(({ key }) => key === chip.key);
      this.chips[chipIndex] = chip;

      return;
    }

    const matchingChips = this.chips.filter(({ key, value }) => key === chip.key && value === chip.value);

    if (matchingChips.length === 0) {
      this.chips.push(chip);
    }
  }

  addFilter({ key, type, value }, shouldNotFireChange?: boolean) {
    let filtersChanged = false;

    if (type === 'multi') {
      if (!this.filters[key]) {
        this.filters[key] = [value];
        filtersChanged = true;
      } else if (!this.filters[key].includes(value)) {
        this.filters[key].push(value);
        filtersChanged = true;
      }
    } else {
      this.filters[key] = value;
      filtersChanged = true;
    }

    if (shouldNotFireChange || !filtersChanged) {
      return;
    }

    this.update.emit(this.filters);
  }

  removeFilter(chip, shouldNotFireChange?: boolean) {
    const { key, value } = chip;

    this.chips = this.chips.filter((item) => item !== chip);

    if (Array.isArray(this.filters[key])) {
      this.filters[key] = this.filters[key].filter((item) => item !== value);
    } else {
      delete this.filters[key];
    }

    if (shouldNotFireChange) {
      return;
    }

    this.update.emit(this.filters);
  }
}
