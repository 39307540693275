import { Component, Input } from '@angular/core';

import { Errors } from '../core/models/errors.model';

@Component({
  selector: 'app-list-errors',
  templateUrl: './list-errors.component.html',
})
export class ListErrorsComponent {
  formattedErrors: string[];

  @Input()
  set errors(errorList: Errors) {
    this.formattedErrors = Object.keys(errorList.errors || {}).map((key) => `${key} ${errorList.errors[key]}`);
  }

  get errorList() {
    return this.formattedErrors;
  }

  handleClose() {
    this.formattedErrors = [];
  }
}
