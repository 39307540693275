import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Tab } from './tab.model';

@Component({
  selector: 'app-pages-tabs',
  templateUrl: './pages-tabs.component.html',
  styleUrls: ['./pages-tabs.component.scss'],
})
export class PagesTabsComponent implements OnInit, OnDestroy {
  @Input() tabs: Tab[];
  currentTab: string;
  routeEventsSubscriber: Subscription;
  routeEventsChildrenSubscriber: Subscription;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  parseActiveRoute() {
    let id;
    this.activatedRoute.params.subscribe((params) => {
      id = params.id;
    });

    this.currentTab = `${this.activatedRoute.firstChild.snapshot.data.tab}${id || ''}`;
  }

  subscribeNavigationEnd() {
    // Used to listen the router changes, to get the children changes
    this.routeEventsChildrenSubscriber = this.router.events.subscribe((routeEvents) => {
      if (!(routeEvents instanceof NavigationEnd)) {
        return;
      }

      if (typeof this.activatedRoute.firstChild !== 'undefined' && this.activatedRoute.firstChild) {
        this.parseActiveRoute();
      }
    });
  }

  subscribeRouterChange() {
    if (!this.activatedRoute.firstChild || (this.activatedRoute.firstChild && !this.activatedRoute.firstChild.params)) {
      return;
    }

    // Used to get the first shopId - for the scenario when the user refreshes the page
    this.routeEventsSubscriber = this.activatedRoute.firstChild.params.subscribe(() => {
      this.parseActiveRoute();
    });
  }

  ngOnInit() {
    this.subscribeRouterChange();
    this.subscribeNavigationEnd();
  }

  ngOnDestroy() {
    if (this.routeEventsChildrenSubscriber) {
      this.routeEventsChildrenSubscriber.unsubscribe();
    }

    if (this.routeEventsSubscriber) {
      this.routeEventsSubscriber.unsubscribe();
    }
  }
}
