import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ProductFilters, ProductMetadataFilters } from '../../../../shared/models/filters';
import { PaginatedResult } from '../models/paginated.result.model';
import { ShopProduct } from '../models/shop-product.model';
import { ApiService } from './api.service';

@Injectable()
export class ShopProductsService {
  constructor(private apiService: ApiService) {}

  getAll(filters?: ProductFilters): Promise<PaginatedResult> {
    const params = this.apiService.parseFiltersToHttpParams(filters);

    return this.apiService.getPaginated('/products/filter', params).toPromise();
  }

  getMetadata(filters?: ProductMetadataFilters) {
    const params = this.apiService.parseFiltersToHttpParams(filters);

    return this.apiService.get('/products/metadata', params).toPromise();
  }

  save(product: ShopProduct): Promise<ShopProduct> {
    if (!product.id) {
      return this.apiService
        .post(`/products`, product)
        .pipe(map((data: ShopProduct) => data as ShopProduct))
        .toPromise();
    }

    return this.apiService
      .put(`/products`, product)
      .pipe(map((data: ShopProduct) => data as ShopProduct))
      .toPromise();
  }

  update(product: ShopProduct): Promise<ShopProduct> {
    return this.apiService
      .put(`/products`, product)
      .pipe(map((data: ShopProduct) => data as ShopProduct))
      .toPromise();
  }

  updatePriorities(products) {
    return this.apiService
      .put(`/products/priorities`, products)
      .pipe(map((data: ShopProduct[]) => data as ShopProduct[]))
      .toPromise();
  }

  delete(id: number): Promise<boolean> {
    return this.apiService
      .delete(`/products/${id}`)
      .pipe(map((data: boolean) => data as boolean))
      .toPromise();
  }
}
