import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';

import { User } from '../models/user.model';
import { UserStore } from '../stores';
import { AccessControlService } from './access-control.service';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';

@Injectable()
export class UserService {
  constructor(
    private userStore: UserStore,
    private amplifyService: AmplifyService,
    private jwtService: JwtService,
    private apiService: ApiService,
    private accessControlService: AccessControlService,
  ) {
    this.amplifyService.authStateChange$.subscribe(async (authState) => {
      if (!authState.user) {
        return this.setAuth({ username: null });
      }

      const { username } = authState.user;
      this.getToken((jwtToken) => {
        this.setAuth({ username, jwtToken });
        this.setPermissions();
      });
    });
  }

  isAuthenticated(): Promise<boolean> {
    return Auth.currentAuthenticatedUser().then(
      (user) => {
        if (user) {
          return true;
        }

        return false;
      },
      () => {
        return false;
      },
    );
  }

  async setPermissions() {
    const grants = await this.apiService.get(`/permission/`).toPromise();
    this.accessControlService.setGrants(grants);
  }

  hasPermissionTo(action: string, resource: string) {
    return this.accessControlService.hasPermissionTo(action, resource);
  }

  async getToken(callback: any) {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const { refreshToken } = cognitoUser.getSignInUserSession();
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (err) {
          throw err;
        }
        const { idToken } = session;
        this.jwtService.saveToken(idToken.jwtToken);
        callback(idToken.jwtToken);
      });
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.log(`Unable to refresh Token ${JSON.stringify(e)}`);
    }
  }

  async populate() {
    //
  }

  setAuth(user: User) {
    this.userStore.setUser(user);
  }

  async purgeAuth() {
    await Auth.signOut();
    this.jwtService.saveToken(null);
    this.userStore.setUser(null);
    window.location.href = '/';
  }
}
