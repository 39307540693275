import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageSize',
})
export class ImageSizePipe implements PipeTransform {
  transform(value: string, size: 300 | 2000): any {
    if (!value) {
      return value;
    }

    return value.replace(/[.]([^.]+)$/, (_, ext) => `-${size}.${ext}`);
  }
}
