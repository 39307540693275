import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { UserStore } from '../core/stores';

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(private userService: UserStore) {}

  canActivate() {
    return !this.userService.isAuthenticated;
  }
}
