import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AssetsUsage } from '../models/assets-usage';
import { ApiService } from './api.service';

@Injectable()
export class DashboardService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  populate() {
    return this.http.get(`${this.apiService.apiPath}/dashboard/`).pipe(map((data) => data));
  }

  loadAssetsUsage(): Promise<AssetsUsage[]> {
    return this.http
      .get<AssetsUsage[]>(`${this.apiService.apiPath}/tracking/assets`)
      .pipe(
        map((data: AssetsUsage[]) => {
          return data as AssetsUsage[];
        }),
      )
      .pipe(catchError((error) => throwError(error.error)))
      .toPromise();
  }
}
