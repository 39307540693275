import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable()
export class ShopSlotsService {
  constructor(private apiService: ApiService) {}

  update(payload: any): Observable<any> {
    return this.apiService.put('/slot', payload).pipe(map((data: any) => data));
  }

  getCollections(id: number): Promise<any[]> {
    return this.apiService
      .get(`/slot/collection/${id}`)
      .pipe(map((data: any) => data))
      .toPromise();
  }
}
