import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { autorun } from 'mobx';
import { UserStore } from '../core/stores';

@Directive({ selector: '[appShowAuthed]' })
export class ShowAuthedDirective implements OnInit {
  condition: boolean;

  constructor(
    private templateRef: TemplateRef<any>,
    private userStore: UserStore,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    autorun(() => {
      if (this.userStore.isAuthenticated === this.condition) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

  @Input()
  set appShowAuthed(condition: boolean) {
    this.condition = condition;
  }
}
