import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-draggable-item',
  templateUrl: './draggable-item.component.html',
  styleUrls: ['./draggable-item.component.scss'],
})
export class DraggableItemComponent {
  @Input() item: any;

  @Input() showDeleteButton: boolean;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onClickDelete = new EventEmitter();

  deleteItem() {
    this.onClickDelete.emit({ item: this.item });
  }
}
