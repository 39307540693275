import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { BaseFilterComponent } from '../base-filter-component.abstract';

@Component({
  selector: 'app-filter-text',
  templateUrl: './filter-text.component.html',
  styleUrls: ['./filter-text.component.scss'],
  providers: [{ provide: BaseFilterComponent, useExisting: forwardRef(() => FilterTextComponent) }],
})
export class FilterTextComponent extends BaseFilterComponent implements AfterViewInit {
  @ViewChild('input') input;

  changeTimeout;
  value = '';

  @Input() key: string = 'text';
  @Input() label: string = 'Filter';
  @Input() autofocus: boolean = false;
  @Input() change: (filter: any) => void = () => undefined;

  ngAfterViewInit() {
    if (this.autofocus) {
      this.input.nativeElement.focus();
    }
  }

  handleChange($event) {
    // order moderation and management panels filters after enter key
    if ($event.keyCode === 13) {
      this.value = $event.target.value;

      return this.change({
        type: 'text',
        key: this.key,
        label: this.label,
        value: this.value,
        keyCode: $event.keyCode,
      });
    }

    if ($event.target.value === this.value) {
      return;
    }

    this.value = $event.target.value;

    clearTimeout(this.changeTimeout);
    this.changeTimeout = setTimeout(() => {
      this.change({ type: 'text', key: this.key, label: this.label, value: this.value, keyCode: $event.keyCode });
    }, 500);
  }
}
