import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Design } from '../../core/models/design.model';
import { ShopTemplate } from '../../core/models/shop-template.model';

@Component({
  selector: 'app-design-template-view',
  templateUrl: './design-template-view.component.html',
  styleUrls: ['./design-template-view.component.scss'],
})
export class DesignTemplateViewComponent implements OnInit {
  @Input() design: Design;
  @Input() template: ShopTemplate;
  @Input() title: string;

  constructor(private activeModal: NgbActiveModal) {}

  getFrontImage() {
    return this.getFrontImageFromDesign() || this.getFrontImageFromTemplate();
  }

  getFrontImageFromDesign() {
    if (!this.design) {
      return undefined;
    }

    if (this.design.proofFront) {
      return this.design.proofFront;
    }
    if (this.design.proofFront) {
      return this.design.proofFront;
    }

    return undefined;
  }

  getFrontImageFromTemplate() {
    if (!this.template) {
      return undefined;
    }

    if (this.template.proofFront) {
      return this.template.proofFront;
    }
    if (this.template.proofFront) {
      return this.template.proofFront;
    }

    return undefined;
  }

  getBackImage() {
    return this.getBackImageFromDesign() || this.getBackImageFromTemplate();
  }

  getArtBack() {
    return this.design ? this.design.artBack : '';
  }

  getArtFront() {
    return this.design ? this.design.artFront : '';
  }

  isProcessing() {
    return this.design && this.design.processingStart && !this.design.processingEnd;
  }

  getBackImageFromDesign() {
    if (!this.design) {
      return;
    }

    if (this.design.proofBack) {
      return this.design.proofBack;
    }
    if (this.design.proofBack) {
      return this.design.proofBack;
    }

    return undefined;
  }

  getBackImageFromTemplate() {
    if (!this.template) {
      return;
    }

    if (this.template.proofBack) {
      return this.template.proofBack;
    }
    if (this.template.proofBack) {
      return this.template.proofBack;
    }

    return undefined;
  }

  ngOnInit() {
    if (this.title) {
      return;
    }

    if (this.design) {
      this.title = 'Design';
    }
    if (this.template) {
      this.title = this.template.name;
    }
  }

  public close() {
    this.activeModal.close();
  }
}
