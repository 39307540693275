import { Design } from '../core/models/design.model';

export const statusList = [
  { id: 'pending-designs', name: 'Pending Designs', description: 'There is at least one design pending for approval' },
  { id: 'held-designs', name: 'Designs on held', description: 'There is at least one design on held' },
  {
    id: 'waiting-customer',
    name: 'Product Replacement',
    description: 'There is at least one design on product replacement proccess',
  },
  { id: 'waiting-art', name: 'Waiting arts', description: 'There is at least one design waiting art to be rendered' },
  {
    id: 'rendering-art',
    name: 'Rendering arts',
    description: 'There is at least one design in art rendering processing',
  },
  { id: 'accepted', name: 'Accepted', description: 'All designs were accepted' },
  { id: 'in-process', name: 'In process', description: 'Order is being processed' },
  { id: 'printing', name: 'Printing', description: 'All designs were sent to printer' },
  { id: 'shipped', name: 'Shipped', description: 'Printer has shipped all designs' },
  {
    id: 'rejected',
    name: 'Cancelled',
    description: 'Order rejected due risk, dispute opened, inconsistency or all designs were rejected',
  },
  { id: 'refunded', name: 'Refunded', description: 'Order fully refunded' },
  { id: 'error', name: 'Error', description: 'An error occurred while processing this order. Call the IT guys!' },
];

export const rejectionTypes = [
  { id: 'risk', name: 'Risky' },
  { id: 'dispute', name: 'Dispute' },
];

export const timeList = [
  { id: '1', name: 'Last 24 hours' },
  { id: '30', name: 'Last 30 days' },
  { id: '60', name: 'Last 60 days' },
  { id: '75', name: 'Last 75 days' },
  { id: 'all', name: 'All time' },
];

export interface Order {
  id: number;
  createdAt: string;
  customer: {};
  domestic?: boolean;
  items: OrderItem[];
  remainingHours?: string;
  hours?: number;

  shopifyId?: string;
  fulfillmentStatus?: string;
  country?: string;
  finalPrice?: number;
  status?: string;
  shop?: any;
  shopifyUrl?: string;
  shopifyOrderId?: string;
  printers?: string;
  riskDisputeDetails?: any;
  rejectedReason?: any;
  shippingPriority?: string;
}

export interface OrderItem {
  id: number;
  orderId: number;
  designId: number;
  designs: Array<Partial<Design>>;
  remaining: string;
  orderNumberHidden: boolean;
  date: any;
  customer: any;
  domestic: boolean;
  lastItem: boolean;

  shopifyOrderId?: string;
  history?: any[];

  printer?: string;
  status?: string;
  moderationStatus?: string;
  statusNotes?: string;

  printInfo: any;
  shippingPriority?: string;

  shopId?: number;
}
