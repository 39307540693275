import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import Auth from '@aws-amplify/auth';
import Interactions from '@aws-amplify/interactions';
import Storage from '@aws-amplify/storage';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AmplifyAngularModule, AmplifyModules, AmplifyService } from 'aws-amplify-angular';
import { MobxAngularModule } from 'mobx-angular';
import { DragulaModule } from 'ng2-dragula';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { S3Provider } from '../app/core/providers/s3.provider';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.router';
import { CoreModule } from './core/core.module';
import { DesignsService } from './core/services/designs.service';
import { ShopCollectionsService } from './core/services/shop-collections.service';
import { ShopImagesService } from './core/services/shop-images.service';
import { DesignTemplateFormComponent } from './design/designs/design-template-form/design-template-form.component';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { DesignTemplateViewComponent } from './shared/design-template-view/design-template-view.component';
import { DialogComponent } from './shared/dialog.component';
import { FooterComponent } from './shared/layout/footer.component';
import { SidebarComponent } from './shared/layout/sidebar.component';
import { FilterByName } from './shared/pipes/filter-by-name.pipe';
import { SharedModule } from './shared/shared.module';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    DialogComponent,
    DesignTemplateFormComponent,
    DesignTemplateViewComponent,
    FilterByName,
    FooterComponent,
    SidebarComponent,
  ],
  imports: [
    HomeModule,
    LoginModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    BrowserModule,
    CoreModule,
    DeviceDetectorModule.forRoot(),
    DragulaModule,
    SharedModule,
    NgbModule.forRoot(),
    MobxAngularModule,
    AmplifyAngularModule,
  ],
  providers: [
    S3Provider,
    ShopCollectionsService,
    ShopImagesService,
    DesignsService,
    {
      provide: AmplifyService,
      useFactory: () => {
        return AmplifyModules({
          Auth,
          Storage,
          Interactions,
        });
      },
    },
  ],
  entryComponents: [DialogComponent, DesignTemplateFormComponent, DesignTemplateViewComponent],
})
export class AppModule {}
