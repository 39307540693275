import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
})
export class DialogComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() okButtonTitle: string;
  @Input() cancelButtonTitle: string;

  constructor(public activeModal: NgbActiveModal, public changeRef: ChangeDetectorRef) {}

  public close() {
    this.activeModal.close();
  }
}
