import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-text-list',
  templateUrl: './text-list.component.html',
  styleUrls: ['./text-list.component.scss'],
})
export class TextListComponent implements OnInit {
  @Input() list: string[];
  @Input() type: 'domain';
  @Input() numbered: boolean = false;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() listChange = new EventEmitter<string[]>();

  masks = {
    domain: /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
  };

  changeTimeout;

  get mask() {
    if (!this.type) {
      return;
    }

    return this.masks[this.type];
  }

  ngOnInit() {
    if (!this.list) {
      this.list = [];
    }

    if (this.mask && this.list.length) {
      this.list = this.list.map((item) => this.format(item));
      this.listChange.emit(this.list);
    }
  }

  format(value) {
    if (!this.mask) {
      return value;
    }
    const match = value.match(this.mask);

    return match ? match.join('') : '';
  }

  addItem() {
    this.list.push('');
  }

  removeItem(itemIndex) {
    this.list.splice(itemIndex, 1);
    this.listChange.emit(this.list);
  }

  handleChange(index, $event) {
    clearTimeout(this.changeTimeout);

    this.changeTimeout = setTimeout(() => {
      const item = this.format($event.target.value);
      const repeatedItem = this.list.find((listItem) => listItem === item);

      if (item.length && repeatedItem) {
        return;
      }

      this.list[index] = item;

      const listLength = this.list.length;
      this.list = this.list.filter((listItem, i) => listItem.length || i === listLength - 1);

      this.listChange.emit(this.list);
    }, 2000);
  }
}
