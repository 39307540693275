import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appImageFallback]',
})
export class ImageFallbackDirective implements OnDestroy {
  @Input() appImageFallback: string;

  constructor(private el: ElementRef) {
    this.el.nativeElement.addEventListener('error', this.onError);
  }

  onError = () => {
    this.el.nativeElement.removeEventListener('error', this.onError);

    this.el.nativeElement.setAttribute('src', this.appImageFallback);
  };

  ngOnDestroy() {
    this.el.nativeElement.removeEventListener('error', this.onError);
  }
}
