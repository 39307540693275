import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Order } from '../../orders/orders.model';
import { PaginatedResult } from '../models/paginated.result.model';
import { ApiService } from './api.service';

@Injectable()
export class OrdersService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get apiPath() {
    return this.apiService.apiPath;
  }

  loadOrdersDesigns(searchBy: any = {}): Promise<PaginatedResult> {
    const httpParams = {
      ...searchBy,
    };

    return this.http
      .get<PaginatedResult>(`${this.apiPath}/order/`, { params: httpParams })
      .pipe(catchError((error) => throwError(error.error)))
      .toPromise();
  }

  loadOrders(searchBy: any = {}): Promise<PaginatedResult> {
    const httpParams = {
      ...searchBy,
    };

    return this.http
      .get<PaginatedResult>(`${this.apiPath}/order/list`, { params: httpParams })
      .pipe(catchError((error) => throwError(error.error)))
      .toPromise();
  }

  loadFullOrder(orderId): Promise<Order> {
    return this.http
      .get<Order>(`${this.apiPath}/order/details/${orderId}`)
      .pipe(catchError((error) => throwError(error.error)))
      .toPromise();
  }

  generateProofs(orderItemId): Promise<any> {
    return this.http
      .post<any>(`${this.apiPath}/order/proofs/${orderItemId}`, {})
      .pipe(catchError((error) => throwError(error.error)))
      .toPromise();
  }

  refund(ordersIds): Promise<any> {
    return this.http
      .post<any>(`${this.apiPath}/order/refund`, { ordersIds })
      .pipe(catchError((error) => throwError(error.error)))
      .toPromise();
  }

  resendOrdersToPrinter(failingOrders: Order[], isFullOrder: boolean): Promise<any> {
    return this.http
      .post<any>(`${this.apiPath}/order/resendToPrinter`, { orders: failingOrders, isFullOrder })
      .pipe(catchError((error) => throwError(error.error)))
      .toPromise();
  }

  getProductFilters(searchBy: any = {}): Promise<any> {
    const httpParams = {
      ...searchBy,
    };

    return this.http
      .get<any>(`${this.apiPath}/order/productFilters`, { params: httpParams })
      .pipe(catchError((error) => throwError(error.error)))
      .toPromise();
  }

  updateModerationStatus(orderItemId, status): Promise<any> {
    return this.http
      .put<any>(`${this.apiPath}/order/item/${orderItemId}/status`, { status })
      .pipe(catchError((error) => throwError(error.error)))
      .toPromise();
  }
}
