import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { configure } from 'mobx';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

configure({
  enforceActions: 'strict',
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    // tslint:disable-next-line:no-console
    console.log(err);
  });
