import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as urljoin from 'url-join';
import { environment } from '../../../environments/environment';
import { PaginatedResult } from '../models/paginated.result.model';
import { ShopStore, UserStore } from '../stores';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, private userStore: UserStore, private shopStore: ShopStore) {}

  private currentPath: string = '';

  get apiPath() {
    let username;
    if (this.userStore.currentUser) {
      username = this.userStore.currentUser.username;
    }

    const routes = ['/dashboard/', '/variants/', '/order/', '/tracking', '/shop/', '/design/', '/permission/'];

    let shop = '';
    if (this.shopStore.shop && !routes.some((route) => this.currentPath.startsWith(route))) {
      shop = `/shop/${this.shopStore.shop.id}`;
    }

    return urljoin(environment.apiUrl, `/profile/${username}${shop}`);
  }

  get apiPrivatePath() {
    let username;
    if (this.userStore.currentUser) {
      username = this.userStore.currentUser.username;
    }

    return urljoin(environment.apiUrl, `/profile/${username}`);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    this.currentPath = path;

    return this.http.get(`${this.apiPath}${path}`, { params }).pipe(catchError(this.formatErrors));
  }

  getPaginated(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    this.currentPath = path;

    return this.http
      .get<PaginatedResult>(`${this.apiPath}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: any = {}): Observable<any> {
    this.currentPath = path;

    return this.http.put(`${this.apiPath}${path}`, body).pipe(catchError(this.formatErrors));
  }

  patch(path: string, body: any = {}): Observable<any> {
    this.currentPath = path;

    return this.http.patch(`${this.apiPath}${path}`, body).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: any = {}): Observable<any> {
    this.currentPath = path;

    return this.http.post(`${this.apiPath}${path}`, body).pipe(catchError(this.formatErrors));
  }

  delete(path): Observable<any> {
    this.currentPath = path;

    return this.http.delete(`${this.apiPath}${path}`).pipe(catchError(this.formatErrors));
  }

  formatErrors(error: any) {
    if (error.status === 403 && error.error.error === 'Failed to authenticate token.') {
      location.href = '/login';
    }

    return throwError(error.error);
  }

  parseFiltersToHttpParams(filters) {
    let params = new HttpParams();

    if (!filters) {
      return params;
    }

    Object.keys(filters).forEach((key) => {
      const filter = filters[key];

      if (filter === undefined) {
        return;
      }

      if (Array.isArray(filter)) {
        filter.forEach((item) => {
          params = params.append(`${key}[]`, item);
        });

        return;
      }

      params = params.append(key, filter);
    });

    return params;
  }
}
