import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Cut } from '../../../../shared/models/cut.model';
import { TemplateException } from '../../../../shared/models/exception.model';
import {
  TemplateExceptionFilters,
  TemplateFilters,
  TemplateImageFilters,
  TemplateImageTagFilters,
} from '../../../../shared/models/filters';
import { GarmentColor } from '../models/garment-color.model';
import { PaginatedResult } from '../models/paginated.result.model';
import { Base64Image, ShopTemplate, Side, SlotRule, TemplateTag } from '../models/shop-template.model';
import { ApiService } from './api.service';
@Injectable()
export class ShopTemplatesService {
  constructor(private apiService: ApiService) {}

  create(payload: any): Observable<ShopTemplate> {
    const { shop, design, name } = payload;

    return this.apiService
      .post(`/shop/${shop}/template`, { shop, design, name })
      .pipe(map((data: ShopTemplate) => data as ShopTemplate));
  }

  updateDesign(payload: any) {
    return this.apiService.put(`/template/update-design`, payload).toPromise();
  }

  duplicate(templateId: number, garmentType?: Cut): Promise<ShopTemplate> {
    const garmentParam = garmentType ? `/${garmentType}` : '';

    return this.apiService
      .post(`/template/duplicate/${templateId}${garmentParam}`)
      .pipe(map((data: ShopTemplate) => data as ShopTemplate))
      .toPromise();
  }

  getTemplateTags(templateId: number): Promise<TemplateTag[]> {
    return this.apiService
      .get(`/template/${templateId}/tags`)
      .pipe(map((data: TemplateTag[]) => data as TemplateTag[]))
      .toPromise();
  }

  saveTemplateTags(templateId: number, templateTags: string) {
    return this.apiService.post(`/template/${templateId}/tags`, { templateTags }).toPromise();
  }

  getTemplateColors(garmentType?): Observable<GarmentColor[]> {
    return this.apiService
      .get(`/template/garment/colors/${garmentType}`)
      .pipe(map((data: GarmentColor[]) => data as GarmentColor[]));
  }

  getAllTemplateTags(): Promise<TemplateTag[]> {
    return this.apiService
      .get(`/template/tags/all`)
      .pipe(map((data: TemplateTag[]) => data as TemplateTag[]))
      .toPromise();
  }

  getTemplateImages(filters?: TemplateImageFilters): Promise<PaginatedResult> {
    const params = this.apiService.parseFiltersToHttpParams(filters);

    return this.apiService.getPaginated(`/template/images/${filters.templateId}`, params).toPromise();
  }

  getTemplateImageTags(filters?: TemplateImageTagFilters): Promise<PaginatedResult> {
    const params = this.apiService.parseFiltersToHttpParams(filters);

    return this.apiService.getPaginated(`/template/images/tags/${filters.templateId}`, params).toPromise();
  }

  getTemplateSlots(id: number): Promise<any[]> {
    return this.apiService
      .get(`/template/slots/${id}`)
      .pipe(map((data: any[]) => data as any[]))
      .toPromise();
  }

  getTemplateSlotsPalettes(id: number): Observable<any[]> {
    return this.apiService.get(`/template/slots-palettes/${id}`).pipe(map((data: any[]) => data as any[]));
  }

  getTemplateExceptions(filters?: TemplateExceptionFilters): Promise<PaginatedResult> {
    const params = this.apiService.parseFiltersToHttpParams(filters);

    return this.apiService.getPaginated(`/template/exceptions/${filters.templateId}`, params).toPromise();
  }

  getTemplateExceptionsMap(id: number): Promise<any> {
    return this.apiService.getPaginated(`/template/exceptions-map/${id}`).toPromise();
  }

  updateTemplateExceptions(
    templateId: number,
    updates: { add?: TemplateException[]; remove?: TemplateException[] },
  ): Promise<TemplateException[]> {
    return this.apiService.put('/template/exceptions', { templateId, updates }).toPromise();
  }

  getTemplateCollections(id: number): Observable<any> {
    return this.apiService.get(`/template/collections/${id}`).pipe(map((data: any) => data as any));
  }

  updateTemplateCollections(templateId: number, collections: any): Observable<any> {
    return this.apiService.put('/template/collections', { templateId, collections }).pipe(map((data: any) => data));
  }

  getTemplateSlotRules(id: number): Observable<SlotRule[]> {
    return this.apiService.get(`/template/slot-rules/${id}`).pipe(map((data: SlotRule[]) => data as SlotRule[]));
  }

  update(payload: any): Promise<ShopTemplate> {
    return this.apiService
      .put('/template', payload)
      .pipe(map((data: ShopTemplate) => data))
      .toPromise();
  }

  patch(payload: any): Promise<ShopTemplate> {
    return this.apiService
      .patch('/template', payload)
      .pipe(map((data: ShopTemplate) => data))
      .toPromise();
  }

  updateDesignData(payload: any): Promise<ShopTemplate> {
    return this.apiService
      .put('/template/design-data', payload)
      .pipe(map((data: ShopTemplate) => data))
      .toPromise();
  }

  getAllByShop(filters?: TemplateFilters): Observable<PaginatedResult> {
    const params = this.apiService.parseFiltersToHttpParams(filters);

    return this.apiService.getPaginated(`/template/`, params);
  }

  get(id: number): Observable<ShopTemplate> {
    return this.apiService.get(`/template/${id}`).pipe(map((data: ShopTemplate) => data as ShopTemplate));
  }

  createFromPSD(payload: any): Promise<ShopTemplate> {
    return this.apiService
      .post(`/template/from-psd`, payload)
      .pipe(map((data: ShopTemplate) => data as ShopTemplate))
      .toPromise();
  }

  deleteTemplate(templateId: number) {
    return this.apiService
      .delete(`/template/${templateId}`)
      .pipe(map((data: ShopTemplate) => data as ShopTemplate))
      .toPromise();
  }

  getBase64ProofImage(templateId: number, designNumber: string, section: Side): Promise<Base64Image> {
    return this.apiService
      .get(`/template/preview/${templateId}/${section}/${designNumber}`)
      .pipe(map((data: Base64Image) => data as Base64Image))
      .toPromise();
  }

  addShopifyProductStatus(payload: any) {
    return this.apiService.post('/shopifyProductStatus', payload).toPromise();
  }
}
