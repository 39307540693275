import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FileUploadModule } from 'ng2-file-upload';
import { DesignsService } from '../core/services/designs.service';
import { DesignStore, UserStore } from '../core/stores';

import { AppColorStepComponent } from './app-color-step/app-color-step.component';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { ColorInputComponent } from './color-input/color-input.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ImageFallbackDirective } from './directives/image-fallback.directive';
import { NgbdSortableHeader } from './directives/table-sort.directive';
import { DraggableItemComponent } from './draggable-item/draggable-item.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { FilterDropdownComponent } from './filter/filter-dropdown/filter-dropdown.component';
import { FilterInputComponent } from './filter/filter-input/filter-input.component';
import { FilterTextComponent } from './filter/filter-text/filter-text.component';
import { FilterToggleComponent } from './filter/filter-toggle/filter-toggle.component';
import { FilterComponent } from './filter/filter.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { InfoPanelComponent } from './info-panel/info-panel.component';
import { ListErrorsComponent } from './list-errors.component';
import { PagesTabsComponent } from './pages-tabs/pages-tabs.component';
import { ArrayToCSVPipe } from './pipes/array-to-csv.pipe';
import { ImageSizePipe } from './pipes/image-size.pipe';
import { OrderStatusDecoderPipe } from './pipes/order-status-decoder.pipe';
import { ShowAuthedDirective } from './show-authed.directive';
import { TextListComponent } from './text-list/text-list.component';

@NgModule({
  declarations: [
    ListErrorsComponent,
    ShowAuthedDirective,
    ImageSizePipe,
    ArrayToCSVPipe,
    ImageFallbackDirective,
    AppColorStepComponent,
    BulkUploadComponent,
    ColorPickerComponent,
    DraggableItemComponent,
    PagesTabsComponent,
    DropdownComponent,
    ColorInputComponent,
    OrderStatusDecoderPipe,
    NgbdSortableHeader,
    InfoPanelComponent,
    FilterComponent,
    FilterTextComponent,
    FilterDropdownComponent,
    FilterToggleComponent,
    ImageUploaderComponent,
    TextListComponent,
    FilterInputComponent,
  ],
  exports: [
    ArrayToCSVPipe,
    CommonModule,
    FormsModule,
    ListErrorsComponent,
    ReactiveFormsModule,
    RouterModule,
    ShowAuthedDirective,
    ImageSizePipe,
    ImageFallbackDirective,
    AngularFontAwesomeModule,
    AppColorStepComponent,
    BulkUploadComponent,
    ColorPickerComponent,
    DraggableItemComponent,
    PagesTabsComponent,
    DropdownComponent,
    ColorInputComponent,
    OrderStatusDecoderPipe,
    NgbdSortableHeader,
    InfoPanelComponent,
    FilterComponent,
    FilterTextComponent,
    FilterDropdownComponent,
    FilterToggleComponent,
    ImageUploaderComponent,
    TextListComponent,
    FilterInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    FileUploadModule,
    AngularFontAwesomeModule,
  ],
  providers: [DesignStore, DesignsService, UserStore],
})
export class SharedModule {}
