import { Injectable } from '@angular/core';

export const contrastThreshold = 100;

export const defaultTextColors = {
  light: '#f8f9fa',
  dark: '#343a40',
};

@Injectable()
export class ColorService {
  hexToRgb(hex) {
    const splitHex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);

    return (
      splitHex && {
        r: parseInt(splitHex[1], 16),
        g: parseInt(splitHex[2], 16),
        b: parseInt(splitHex[3], 16),
        a: splitHex[4] && '00',
      }
    );
  }

  getTextColor(backgroundColor) {
    if (!backgroundColor) {
      return defaultTextColors.dark;
    }

    const rgb = this.hexToRgb(backgroundColor);

    if (!rgb) {
      return defaultTextColors.dark;
    }

    const coeficient = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;

    if (coeficient >= contrastThreshold) {
      return defaultTextColors.dark;
    }

    return defaultTextColors.light;
  }
}
