import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { UserService } from '../services/user.service';
import { UserStore } from '../stores';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService, private userStore: UserStore) {}

  private async delay(seconds: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => resolve(), seconds * 1000);
    });
  }

  public canActivate(): Promise<boolean> {
    return new Promise(async (resolve, _) => {
      for (let i = 1; i <= 10; i++) {
        if (this.userStore.currentUser) {
          return resolve(this.userService.isAuthenticated());
        }
        await this.delay(1);
      }

      return resolve(false);
    });
  }
}
