import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Data, DataType } from './dropdown.model';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  @Input() data: Data[];
  @Input() selectedId: number;
  @Input() type: DataType = 'text';

  @Input() width: number;
  @Input() height: number;
  @Input() disabled: boolean = false;
  @Input() resetOnSelect: boolean = false;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() change = new EventEmitter();

  selectedIndex: number = 0;
  optionsOpen: boolean = false;

  ngOnInit() {
    if (this.selectedId && !this.resetOnSelect) {
      this.selectedIndex = this.data.findIndex((item) => item.id === this.selectedId);
    }
  }

  get selected() {
    return this.data[this.selectedIndex];
  }

  toggleOptions($event) {
    if ($event && $event.target.classList.contains('disabled')) {
      return;
    }

    this.optionsOpen = !this.optionsOpen;
  }

  selectOption($event, index) {
    if ($event && $event.target.classList.contains('disabled')) {
      return;
    }

    this.selectedIndex = this.resetOnSelect ? 0 : index;
    this.optionsOpen = false;

    this.change.emit(this.data[index]);
  }
}
