import { Injectable } from '@angular/core';
import { action, observable } from 'mobx-angular';

@Injectable()
class DesignStore {
  @observable acceptedDesign: any;
  @observable rejectedDesign: any;
  @observable newAction: any;
  @observable showMessageBar: boolean;
  @observable currentAction: string;

  constructor() {
    this.initialize();
  }

  @action
  private initialize() {
    this.acceptedDesign = null;
    this.rejectedDesign = null;
  }

  @action
  setAcceptedDesign(accepted: any) {
    this.acceptedDesign = accepted;
  }

  @action
  setRejectedDesign(rejected: any) {
    this.rejectedDesign = rejected;
  }

  @action
  setNewAction(newAction) {
    this.newAction = newAction;
  }

  @action
  setShowMessageBar(showMessageBar) {
    this.showMessageBar = showMessageBar;
  }
  @action
  setCurrentAction(currentAction) {
    this.currentAction = currentAction;
  }
}

export default DesignStore;
