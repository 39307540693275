import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login.component';
import { NoAuthGuard } from './no-auth-guard.service';

const routes: Routes = [
  {
    canActivate: [NoAuthGuard],
    component: LoginComponent,
    path: '',
  },
];
@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class LoginRoutingModule {}
