import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable()
export class ShopSlotRulesService {
  constructor(private apiService: ApiService) {}

  create(slotRule: any): Observable<any> {
    return this.apiService.post('/slot-rule', { slotRule }).pipe(map((data: any) => data));
  }

  update(slotRules: any[]): Observable<any> {
    return this.apiService.put('/slot-rule ', { slotRules }).pipe(map((data: any) => data));
  }

  delete(id: number): Observable<any> {
    return this.apiService.delete(`/slot-rule/${id}`).pipe(map((data: any) => data));
  }
}
