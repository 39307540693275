import { Pipe, PipeTransform } from '@angular/core';

import { statusList } from '../../orders/orders.model';

@Pipe({ name: 'orderStatusDecode' })
export class OrderStatusDecoderPipe implements PipeTransform {
  transform(status: string): string {
    const finalStatus = statusList.find((orderStatus) => orderStatus.id === status);
    if (!finalStatus) {
      return '';
    }

    return finalStatus.name;
  }
}
