import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { AccessControlService } from './services/access-control.service';
import { ApiService } from './services/api.service';
import { AuthGuard } from './services/auth-guard.service';
import { ColorService } from './services/color.service';
import { DashboardService } from './services/dashboard.service';
import { FontsService } from './services/fonts.service';
import { JwtService } from './services/jwt.service';
import { OrdersService } from './services/orders.service';
import { ShopSlotsParserService } from './services/parsers/shop-slots-parser.service';
import { ShopDomainsService } from './services/shop-domains.service';
import { ShopProductsService } from './services/shop-products.service';
import { ShopSlotRulesService } from './services/shop-slot-rules.service';
import { ShopSlotsService } from './services/shop-slots.service';
import { ShopStorefrontService } from './services/shop-storefront.service';
import { ShopTemplateConfigsService } from './services/shop-template-configs.service';
import { ShopTemplatesService } from './services/shop-templates.service';
import { ShopService } from './services/shop.service';
import { UserService } from './services/user.service';
import { VariantsService } from './services/variants.service';
import { DesignStore, ShopStore, UserStore } from './stores';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
    AuthGuard,
    ColorService,
    JwtService,
    ShopDomainsService,
    ShopProductsService,
    ShopSlotsParserService,
    ShopSlotRulesService,
    ShopSlotsService,
    ShopStorefrontService,
    ShopTemplateConfigsService,
    ShopTemplatesService,
    FontsService,
    ShopService,
    UserService,
    AccessControlService,
    ShopStore,
    DesignStore,
    UserStore,
    OrdersService,
    DashboardService,
    VariantsService,
  ],
})
export class CoreModule {}
