import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PaginatedResult } from '../models/paginated.result.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class VariantsService {
  constructor(private apiService: ApiService) {}

  loadVariants(searchBy: any = {}): Promise<PaginatedResult> {
    const httpParams = {
      ...searchBy,
    };

    return this.apiService
      .get(`/variants/`, httpParams)
      .pipe(catchError((error) => throwError(error.error)))
      .toPromise();
  }

  loadPrintables() {
    return this.apiService
      .get(`/variants/printables`)
      .pipe(catchError((error) => throwError(error.error)))
      .toPromise();
  }

  loadPrintablesColors() {
    return this.apiService
      .get(`/variants/colors`)
      .pipe(catchError((error) => throwError(error.error)))
      .toPromise();
  }

  updateVariantAvailability(id, active) {
    return this.apiService
      .post(`/variants/${id}/${active}`)
      .pipe(catchError((error) => throwError(error.error)))
      .toPromise();
  }
}
