import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { TemplateConfigFilters } from '../../../../shared/models/filters';
import { PaginatedResult } from '../models/paginated.result.model';
import { TemplateConfig } from '../models/template-config.model';
import { ApiService } from './api.service';

@Injectable()
export class ShopTemplateConfigsService {
  constructor(private apiService: ApiService) {}

  getAll(templateId: number, filters?: TemplateConfigFilters): Promise<PaginatedResult> {
    const params = this.apiService.parseFiltersToHttpParams(filters);

    return this.apiService.getPaginated(`/template/config/${templateId}`, params).toPromise();
  }

  create(config: TemplateConfig): Promise<TemplateConfig> {
    return this.apiService
      .post('/template/config', { config })
      .pipe(map((data: TemplateConfig) => data as TemplateConfig))
      .toPromise();
  }

  update(config: TemplateConfig): Promise<TemplateConfig | any> {
    return this.apiService
      .put('/template/config', { config })
      .pipe(map((data: TemplateConfig | any) => data as TemplateConfig | any))
      .toPromise();
  }

  delete(id: number): Promise<any> {
    return this.apiService
      .delete(`/template/config/${id}`)
      .pipe(map((data: any) => data as any))
      .toPromise();
  }
}
