import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { JwtService } from '../services/jwt.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      Accept: 'application/json',
      Authorization: '',
      'Content-Type': 'application/json',
    };
    let request;

    if (req.url.includes('shirt-app.s3')) {
      request = req.clone({ withCredentials: false });
    } else {
      const token = this.jwtService.getToken();
      if (token) {
        headersConfig.Authorization = `${token}`;
      }

      request = req.clone({ setHeaders: headersConfig, withCredentials: true });
    }

    return next.handle(request);
  }
}
