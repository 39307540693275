import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import exifr from 'exifr'
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core/services/api.service';
import { ShopStore } from '../../core/stores';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss'],
})
export class BulkUploadComponent implements OnInit {
  isUploading: boolean;
  @Input() options: FileUploaderOptions;
  // tslint:disable: no-output-on-prefix
  @Output() onImageUpload = new EventEmitter();
  @Output() onImageRemove = new EventEmitter();

  public uploader: FileUploader;
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  constructor(private shopStore: ShopStore, private apiService: ApiService) {
    this.isUploading = false;
  }

  clearQueue() {
    this.isUploading = false;
    this.uploader.clearQueue();
  }

  cancelAll() {
    this.isUploading = false;
    this.uploader.cancelAll();
  }

  uploadAll() {
    this.isUploading = true;
    this.uploader.uploadAll();
  }

  isUploadAllDisabled() {
    return this.isUploading || !this.uploader.getNotUploadedItems().length;
  }

  getMetadataInformation(file: File){
    return new Promise((resolve,reject)=>{ 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload =  (event) => {
        return resolve(
          exifr.parse(reader.result, { iptc: true }).catch(reject)
        )
      };
    })
  }

  getKeywordsInformation(metadata):string{
    if(!metadata || !metadata.Keywords){
      return null; 
    }

    if(Array.isArray(metadata.Keywords)){
      return metadata.Keywords.join(','); 
    }
    
    return metadata.Keywords
  }

  ngOnInit(): void {
    this.options.disableMultipart = true;
    this.uploader = new FileUploader(this.options);

    const removeFromQueue = this.uploader.removeFromQueue.bind(this.uploader);
    this.uploader.removeFromQueue = async (item) => {
      const imageID = item.index;
      removeFromQueue(item);

      if(!imageID) {
        return
      }
      this.onImageRemove.emit(item);
      await this.apiService
        .delete(`/image/${imageID}`)
        .pipe(map((data: any) => data))
        .toPromise();
    }
    this.uploader.onAfterAddingFile = async (item) => {
      const signedUrl = await this.apiService
        .post('/image/sign', { mime: item.file.type, shopId: this.shopStore.shop.id })
        .pipe(map((data: any) => data))
        .toPromise();
      item.url = signedUrl.url;
      item.withCredentials = false;
      item.headers = [{ name: 'Content-Type', value: item.file.type, 'Access-Control-Allow-Origin': '*' }];
      item.method = 'PUT';
    };

    this.uploader.onCompleteItem = async (item) => {
      if (item.isError) {
        return this.checkQueue();
      }

      item.isSuccess = false;
      (item as any).isCompressing = true;
      const metadata: any = await this.getMetadataInformation(item._file).catch(console.error)
      const keywords = this.getKeywordsInformation(metadata); 
      const imageUploaded = await this.apiService
        .post('/image/uploaded', {
          shopId: this.shopStore.shop.id,
          url: item.url,
          keywords,
          file: {
            name: item._file.name,
          },
        })
        .pipe(map((data: any) => data))
        .toPromise();
      (item as any).isCompressing = false;
      item.isSuccess = true;
      item.index = imageUploaded.id;
      this.onImageUpload.emit(imageUploaded);
      this.checkQueue();
    };
  }

  public checkQueue() {
    if (!this.uploader.queue || !this.uploader.queue.length) {
      return (this.isUploading = false);
    }

    const isUploading = !!this.uploader.queue.find((item) => item.isUploading);

    return (this.isUploading = isUploading);
  }

  public formatFileName(name: string) {
    if (!name || name.trim() === '' || name.length < 28) {
      return name;
    }
    const type = name.split('.')[1];

    return `${name.slice(0, 21)}...${type}`;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
}
