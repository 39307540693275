import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
})
export class ColorPickerComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() colorsAvailable: string[];
  @Input() preSelectedColors: string[];
  @Input() title: string;
  @Input() defaultColor: string;
  @Input() canSelectOnlyOneColor?: boolean;
  @Input() change: (selectedColors: string[]) => void;

  selectedColors: string[];
  onChange: any;
  onTouched: any;

  colors: string[];

  ngOnChanges(changes: any) {
    if (changes && changes.preSelectedColors) {
      this.selectedColors = this.preSelectedColors || [];
    }
  }

  ngOnInit() {
    this.selectedColors = this.preSelectedColors || [];
    this.getAvailableColours();
  }

  handleChange() {
    if (this.change) {
      this.change(this.selectedColors);
    } else {
      this.onChange(this.selectedColors);
    }
  }

  colorSelected = (event, color: string) => {
    if (this.canSelectOnlyOneColor && event.target.checked) {
      this.selectedColors = [color];

      if (this.change) {
        return this.change(this.selectedColors);
      }

      return this.onChange(this.selectedColors);
    }

    if (event.target.checked) {
      this.pushSelectedColor(color);

      this.handleChange();

      return;
    }

    const colorIndex = this.selectedColors.indexOf(color);
    if (colorIndex !== -1) {
      this.selectedColors.splice(colorIndex, 1);
    }
    this.handleChange();
  };

  private pushSelectedColor(color: string) {
    const selectedColorIndex = this.colors.indexOf(color);

    if (selectedColorIndex === -1) {
      return;
    }

    if (this.selectedColors.length === 0) {
      this.selectedColors.push(color);

      return;
    }

    const newIndex = this.selectedColors.findIndex((c: string) => this.colors.indexOf(c) > selectedColorIndex);
    if (newIndex === -1) {
      this.selectedColors.push(color);

      return;
    }

    this.selectedColors.splice(newIndex, 0, color);
  }

  getChecked = (color: string) => {
    return this.selectedColors.indexOf(color) !== -1;
  };

  getAvailableColours = () => {
    if (this.colorsAvailable) {
      this.colors = this.colorsAvailable;

      return;
    }

    this.colors = [
      '#FFFFFF',
      '#010000',
      '#3897F0',
      '#71C050',
      '#FDCB5C',
      '#FD8D32',
      '#ED4956',
      '#D10869',
      '#A30ABA',
      '#ED0013',
      '#ED858E',
      '#FFD2D3',
      '#FFDBB4',
      '#FFC382',
      '#D28F46',
      '#996439',
      '#432324',
      '#1D4A29',
      '#272626',
      '#363636',
      '#555555',
      '#737373',
      '#999999',
      '#B3B2B2',
      '#C7C7C7',
      '#DBDBDB',
      '#EFEFEF',
    ];
  };

  registerOnChange = (fn) => {
    this.onChange = fn;
  };

  writeValue = (value: string[]) => {
    if (value) {
      this.selectedColors = value;
    }
  };

  registerOnTouched = (fn) => {
    this.onTouched = fn;
  };

  setDisabledState?(isDisabled: boolean): void {
    return;
  }
}
