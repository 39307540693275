import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ApiService } from '../services/api.service';

export interface ImageTag {
  key: string;
  value: string;
}

export interface S3Item {
  Location: string;
  CdnLocation?: string;
  ETag: string;
  Bucket: string;
  Key: string;
}

@Injectable()
export class S3Provider {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  async uploadBase64(data: string, tags: ImageTag[]) {
    if (!data) {
      return Promise.reject('no data to upload');
    }

    if (data.indexOf('api') === 0) {
      return Promise.resolve({
        Location: data,
        CdnLocation: data,
      } as S3Item);
    }

    const mime = data.split(';')[0].split(':')[1] || 'image/png';
    const clonedTags = [...tags];

    clonedTags.push({ key: 'env', value: environment.env });

    const tagging = clonedTags.map((tag) => `${tag.key}=${encodeURIComponent(tag.value)}`).join('&');

    const options = {
      headers: {
        'Content-Type': mime,
        'x-amz-tagging': tagging,
        'Access-Control-Allow-Origin': '*',
      },

      responseType: 'text' as 'text',
    };

    const buffer = new Buffer(data.replace(/^data:image\/\w+;base64,/, ''), 'base64');

    const file = new Blob([buffer], { type: mime });

    const signedUpload = await this.signUpload(mime);

    const url = new URL(signedUpload.url);
    const uploadLocation = `${url.protocol}//${url.host}${url.pathname}`;

    await this.http.put(signedUpload.url, file, options).toPromise();

    return Promise.resolve({
      Location: uploadLocation,
    } as S3Item);
  }

  async signUpload(mime) {
    return this.apiService
      .post('/image/sign', { mime })
      .pipe(map((data: any) => data))
      .toPromise();
  }
}
