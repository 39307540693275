import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraytocsv',
})
export class ArrayToCSVPipe implements PipeTransform {
  transform(input: any, sep = ','): string {
    return input.join(sep);
  }
}
