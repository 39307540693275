import { Component } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-layout-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  today: number = Date.now();
  env: string = environment.env;

  get color() {
    return {
      dev: 'success',
      acceptance: 'info',
      staging: 'warning',
      production: 'danger',
    }[this.env];
  }
}
