import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShopImage } from '../../core/models/shop-image.model';

import { ShopCollection } from '../models/shop-collection.model';
import { ApiService } from './api.service';

@Injectable()
export class ShopCollectionsService {
  constructor(private apiService: ApiService) {}

  get(id): Promise<ShopCollection> {
    return this.apiService
      .get(`/collection/${id}`)
      .pipe(map((data: ShopCollection) => data as ShopCollection))
      .toPromise();
  }

  getAllByShop(): Observable<ShopCollection[]> {
    return this.apiService.get(`/collection`).pipe(map((data: ShopCollection[]) => data as ShopCollection[]));
  }

  getAll(id): Observable<any[]> {
    return this.apiService.get(`/collection/all/${id}`).pipe(map((data: any[]) => data as any[]));
  }

  delete(collection): Observable<ShopCollection[]> {
    return this.apiService
      .delete(`/collection/${collection.id}`)
      .pipe(map((data: ShopCollection[]) => data as ShopCollection[]));
  }

  save(collection: ShopCollection, addedImages: ShopImage[], deletedImages: ShopImage[]): Observable<ShopCollection> {
    if (collection.id) {
      return this.apiService
        .put('/collection', { ...collection, addedImages, deletedImages })
        .pipe(map((data: ShopCollection) => data));
    }

    return this.apiService
      .post('/collection', { ...collection, addedImages, deletedImages })
      .pipe(map((data: ShopCollection) => data));
  }
}
