import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Design } from '../../core/models/design.model';
import { ApiService } from '../../core/services/api.service';
import { DesignStore } from '../stores';

@Injectable()
export class DesignsService {
  constructor(private apiService: ApiService, private designStore: DesignStore) {}

  setAcceptedDesign(accepted) {
    this.designStore.setAcceptedDesign(accepted);
  }

  setRejectedDesign(rejected) {
    this.designStore.setRejectedDesign(rejected);
  }

  setNewAction(newAction) {
    this.designStore.setNewAction(newAction);
  }

  archive(id: number): Observable<Design> {
    return this.apiService.put('/design/archive', { id }).pipe(map((data: Design) => data));
  }

  accept(accepted): Observable<any> {
    return this.apiService.put('/design/accept', { ...accepted }).pipe(map((data: any) => data));
  }

  reject(rejected): Observable<any> {
    return this.apiService.put('/design/reject', { ...rejected }).pipe(map((data: any) => data));
  }

  sendNewAction(newAction): Observable<any> {
    return this.apiService.put('/design/action', { ...newAction }).pipe(map((data: any) => data));
  }

  populate(status: string): Observable<Design[]> {
    return this.apiService.get(`/design/${status}`).pipe(map((data: Design[]) => data as Design[]));
  }

  adminMode(): Observable<any> {
    return this.apiService.get('/design/admin/mode').pipe(map((data: any) => data as any));
  }

  updateArt(design): Observable<any> {
    return this.apiService.post('/design/update/art', { ...design }).pipe(map((data: any) => data));
  }

  renderArtFiles(designId: number, useNewEditor: boolean) {
    return this.apiService.post(`/design/re-render/art/${designId}`, { useNewEditor }).toPromise();
  }

  async getPendingDesigns(parameter: string): Promise<any> {
    const path = `designs/moderation-count-designs/${parameter}`;
    try {
      const countResult = await fetch(
        environment.env === 'dev' ? `api/${path}` : `${environment.apiUrl}/public/${path}`,
      );

      return await countResult.json();
    } catch (error) {
      throw new Error(error);
    }
  }

  async getPendingDesignsWithShop(shopArray: any[]): Promise<any> {
    const path = `designs/moderation-count-designs/shop?shops=${JSON.stringify(shopArray)
      .replace('[', '')
      .replace(']', '')}`;
    try {
      const countResult = await fetch(
        environment.env === 'dev' ? `api/${path}` : `${environment.apiUrl}/public/${path}`,
      );

      return await countResult.json();
    } catch (error) {
      throw new Error(error);
    }
  }

  async setDesignInreview(designId: number): Promise<any> {
    const path = `designs/set-review-design`;
    try {
      const modificationResult = await fetch(
        environment.env === 'dev' ? `api/${path}` : `${environment.apiUrl}/public/${path}`,
        {
          method: 'PATCH',
          headers: new Headers({ 'content-type': 'application/json' }),
          body: JSON.stringify({
            designId,
          }),
        },
      );

      return await modificationResult.json();
    } catch (error) {
      throw new Error(error);
    }
  }
}
