import { Component, forwardRef, Input } from '@angular/core';
import { BaseFilterComponent } from '../base-filter-component.abstract';

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
  providers: [{ provide: BaseFilterComponent, useExisting: forwardRef(() => FilterInputComponent) }],
})
export class FilterInputComponent extends BaseFilterComponent {
  private innerValue: string = '';

  @Input() key: string = 'text';
  @Input() label: string = 'Filter';
  @Input() change: (filter: any) => void = () => undefined;

  handleSubmit() {
    return this.change({
      type: 'single',
      key: this.key,
      label: this.label,
      value: this.innerValue,
      name: this.innerValue,
    });
  }

  handleClear() {
    this.innerValue = '';

    return this.change({
      type: 'single',
      key: this.key,
      label: this.label,
      value: undefined,
      name: this.innerValue,
    });
  }
}
