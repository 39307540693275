import { Injectable } from '@angular/core';

import { ShopConfig } from '../models/shop-config.model';
import { Shop } from '../models/shop.model';
import { TemplateMap } from '../models/template-map.model';
import { ShopStore } from '../stores';
import { ApiService } from './api.service';

@Injectable()
export class ShopService {
  constructor(private apiService: ApiService, private shopStore: ShopStore) {}

  async updateShops() {
    const shops = await this.apiService.get('/shop?all=true').toPromise();

    this.shopStore.updateShops(shops);

    const active = shops.filter((shop) => shop.active);
    const inactive = shops.filter((shop) => !shop.active);
    this.shopStore.setActiveShops(active);
    this.shopStore.setInactiveShops(inactive);
  }

  async delete(id: number) {
    await this.apiService.delete(`/shop/${id}`).toPromise();

    this.shopStore.removeShop(id);
  }

  async get(id: number): Promise<Shop> {
    return this.apiService.get(`/shop/${id}`).toPromise();
  }

  async geConfigByShopId(id: number): Promise<ShopConfig> {
    return this.apiService.get(`/config`).toPromise();
  }

  async getTemplates(id: number): Promise<TemplateMap> {
    return this.apiService.get(`/template`).toPromise();
  }

  async save(shopToSave: Shop) {
    if (shopToSave.id) {
      await this.apiService.put(`/`, shopToSave).toPromise();
    } else {
      await this.apiService.post('/shop/', shopToSave).toPromise();
    }

    this.shopStore.removeShop(shopToSave);
    this.updateShops();
  }

  async sort(sortedIds: number[]) {
    await this.apiService.put('/shop/sort', { sortedIds: sortedIds.join(',') }).toPromise();

    this.updateShops();
  }

  async syncProductsChannels(shopId: number): Promise<string> {
    return this.apiService.post(`/sync`).toPromise();
  }

  async resume(orderId: number) {
    return this.apiService.post(`/order/${orderId}/resume`).toPromise();
  }

  async resumeAll() {
    return this.apiService.post(`/resume/all`).toPromise();
  }
}
