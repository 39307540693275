import { Injectable } from '@angular/core';
import { computed } from 'mobx';
import { action, observable } from 'mobx-angular';
import { User } from '../models/user.model';

@Injectable()
class UserStore {
  @observable currentUser: User;

  @computed
  get isAuthenticated(): boolean {
    return !!(this.currentUser && this.currentUser.username && this.currentUser.jwtToken);
  }

  @action
  setUser(user: User) {
    this.currentUser = user;
  }
}

export default UserStore;
