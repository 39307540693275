import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ImageFilters } from '../../../../shared/models/filters';
import { ApiService } from '../../core/services/api.service';
import { PaginatedResult } from '../models/paginated.result.model';
import { ShopImage, ShopImageTag } from '../models/shop-image.model';

@Injectable()
export class ShopImagesService {
  constructor(private apiService: ApiService) {}

  getAll(filters?: ImageFilters): Promise<PaginatedResult> {
    const params = this.apiService.parseFiltersToHttpParams(filters);

    return this.apiService.getPaginated('/image', params).toPromise();
  }

  delete(image): Observable<ShopImage[]> {
    return this.apiService.delete(`/image/${image.id}`).pipe(map((data: ShopImage[]) => data as ShopImage[]));
  }

  inactivate(image): Observable<ShopImage[]> {
    return this.apiService
      .put(`/image/deactivate-image/${image.id}`)
      .pipe(map((data: ShopImage[]) => data as ShopImage[]));
  }

  hideAllInTemplateCreator(id: number): Observable<ShopImage[]> {
    return this.apiService
      .put('/image/hide-all-in-template-creator/', { id })
      .pipe(map((data: ShopImage[]) => data as ShopImage[]));
  }

  showAllInTemplateCreator(id: number): Observable<ShopImage[]> {
    return this.apiService
      .put('/image/show-all-in-template-creator/', { id })
      .pipe(map((data: ShopImage[]) => data as ShopImage[]));
  }

  updateImage(image): Observable<ShopImage[]> {
    return this.apiService
      .post(`/image/update/${image.id}`, image)
      .pipe(map((data: ShopImage[]) => data as ShopImage[]));
  }

  getImageTags(imageId: number): Observable<ShopImageTag[]> {
    return this.apiService.get(`/image/${imageId}/tags`).pipe(map((data: ShopImageTag[]) => data as ShopImageTag[]));
  }

  getImagesTagsByIds(imageIds: number[], shopId: number): Observable<ShopImageTag[]> {
    return this.apiService.get(`/image/tags?ids=${imageIds}&shopId=${shopId}`).pipe(map((data) => data));
  }

  getImagesTagsByShopId(shopId: number) {
    return this.apiService.get(`/image/tags?shopId=${shopId}`).pipe(map((data) => data));
  }

  postImageTags(imageId: number, tags: string, deletedTags: string[], addedTags: string[]): Observable<ShopImageTag[]> {
    return this.apiService
      .post(`/image/${imageId}/tags`, {
        tags,
        deletedTags,
        addedTags,
      })
      .pipe(map((data: ShopImageTag[]) => data as ShopImageTag[]));
  }

  postImageGroup(imageId: number, groupName: string) {
    return this.apiService
      .post(`/image/${imageId}/group`, {
        groupName
      })
  }

  save(image: ShopImage): Observable<ShopImage> {
    if (image.id) {
      return this.apiService.put('/image', image).pipe(map((data: ShopImage) => data));
    }

    return this.apiService.post('/image', image).pipe(map((data: ShopImage) => data));
  }

  sign(mime, shopId): Promise<any> {
    return this.apiService
      .post('/image/sign', { mime, shopId })
      .pipe(map((data: any) => data))
      .toPromise();
  }

  appendTagsToImages(tags: string[], imageIds: number[]) {
    return this.apiService.put('/image/bulkUpdate', { tags, imageIds }).pipe(map((data) => data));
  }
}
