import { Component } from '@angular/core';
import { reaction } from 'mobx';

import { ShopService } from '../../core/services/shop.service';
import { UserService } from '../../core/services/user.service';
import { ShopStore, UserStore } from '../../core/stores';

@Component({
  selector: 'app-layout-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  constructor(
    private userService: UserService,
    private shopService: ShopService,
    public shopStore: ShopStore,
    public userStore: UserStore,
  ) {
    reaction(
      () => this.userStore.isAuthenticated,
      (isAuthenticated) => {
        if (isAuthenticated) {
          this.updateMenu();
        }
      },
    );
  }

  hasPermissionTo(action: string, resource: string) {
    return this.userService.hasPermissionTo(action, resource);
  }

  canSeeDashboardTab() {
    return this.hasPermissionTo('read', 'dashboard');
  }

  canSeeVariantsTab() {
    return this.hasPermissionTo('update', 'variants');
  }

  canSeeDesignsTab() {
    return this.hasPermissionTo('read', 'design');
  }

  canSeeOrdersTab() {
    return this.hasPermissionTo('read', 'order');
  }

  canSeeShopsTab() {
    return this.hasPermissionTo('update', 'shop');
  }

  async updateMenu() {
    await this.shopService.updateShops();
  }

  logout(event: Event) {
    event.preventDefault();

    this.userService.purgeAuth();
  }
}
