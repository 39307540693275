import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

import { ShopImagesService } from '../../core/services/shop-images.service';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent implements OnInit {
  @Input() shopId: number;
  @Input() label: string = '';

  // tslint:disable-next-line:no-output-on-prefix
  @Output() upload = new EventEmitter();

  isUploading: boolean = false;

  uploader: FileUploader = new FileUploader({
    allowedFileType: ['image'],
    allowedMimeType: ['image/jpeg', 'image/png'],
    disableMultipart: true,
  });

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(private shopImagesService: ShopImagesService) {}

  ngOnInit() {
    this.uploader.onAfterAddingFile = async (item) => {
      this.isUploading = true;
      const signedUrl = await this.shopImagesService.sign(item.file.type, this.shopId);
      item.url = signedUrl.url;
      item.withCredentials = false;
      item.headers = [{ name: 'Content-Type', value: item.file.type, 'Access-Control-Allow-Origin': '*' }];
      item.method = 'PUT';
      item.upload();
    };

    this.uploader.onCompleteItem = async ({ url }) => {
      this.upload.emit(url.split('?')[0]);
      this.uploader.clearQueue();
      this.isUploading = false;
    };
  }

  openFileInput() {
    if (this.isUploading || !this.fileInput) {
      return;
    }
    this.fileInput.nativeElement.click();
  }
}
